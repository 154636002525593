@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
}

/* .ps-sidebar-container::-webkit-scrollbar {
  display: none;
} */
/* For webkit-based browsers */
.overflow-scroll::-webkit-scrollbar {
  width: 3px; /* width of the scrollbar */
  border-radius: 8px;
  /* display: flex; */
  /* background: #1A2F4D; */
}

.overflow-scroll::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 6px;
}

.overflow-scroll::-webkit-scrollbar-track {
  background-color: white;
}

.socialscreen-scroll {
  overflow-y: scroll;
}

.socialscreen-scroll::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
  border-radius: 8px;
}

input[type='checkbox'] {
  appearance: none;
  position: relative;
}

input[type='checkbox']::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #777; /* Desired tick color */
  background-color: white; /* Desired background color of the checkbox */
}

input[type='checkbox']:checked::before {
  /* Styling for the checked checkbox tick */
  content: '\2713'; /* Unicode character for checkmark */
  font-size: 10px;
  color: white; /* Desired tick color */
  text-align: center;
  line-height: 12px;
  border: 1px solid #42CC71;
  background-color: #42CC71;
  padding-bottom: 2px;
  position: relative;
  bottom: 3px;
}

.fixed {
  position: fixed;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top {
  top: 50%;
}

.transform {
  transform: translate(-50%, -50%);
}

.z-50 {
  z-index: 50;
}

.issue {
  font-size: 11px !important;
}

.shadow-right-bottom {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

/* Next and Previous Button */
.button-neprev {
  width: 85px;
  height: 40px;
  align-items: center;
  text-align: center;
  padding: 0px !important;
}

.main-div {
  /* Your default styles for the main div */
  transition: transform 0.5s ease;
  -webkit-backface-visibility: visible;
  backface-visibility: var(background-color);
  /* Other styles as needed */
}

.flip {
  transform: rotateY(180deg);
}

.front,
.back {
  /* Styles for front and back sides */
  /* Adjust dimensions, background, and other styles */
  height: 200px;
  width: 300px;
  border: 1px solid #000;
  /* Other styles as needed */
}

.front {
  /* Styles for the front side */
  background-color: #fff;
}

.back {
  transform: rotateY(180deg);
  /* Styles for the back side */
  background-color: #fff;
}

.back table {
  /* Styles for the table in the back side */
  /* Adjust dimensions, font size, and other styles */
  width: 100%;
  font-size: 16px;
  /* Other styles as needed */
}

.flip-card {
  background-color: transparent;
  /* width: 300px;
  height: 300px; */
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  /* width: 100%;
  height: 100%; */
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  /* width: 100%;
  height: 100%; */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

/* Table font highlited text */
.custom-font-weight {
  font-weight: 1000;
}

.text-rotate {
  writing-mode: vertical-lr; /* Vertical text from bottom to top */
  transform: rotate(-180deg); /* Flip text 180 degrees to read correctly */
}

.tooltip-container {
  position: absolute;
  top: 10px; /* Adjust this value as needed to position the tooltip above the table row */
  left: 80%;
  transform: translateX(-50%);
  width: 200px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.tooltip-heading {
  font-weight: bold;
  margin-bottom: 5px;
}

.tooltip-description {
  font-size: 14px;
  color: #555;
}

/* Status Buttons for forms */
.status-buttons {
  position: relative; /* 1 */
  display: inline-block; /* 2 */
}
.status-buttons::after {
  /* 3 */
  content: '';
  position: absolute;
  width: 50%;
  z-index: -1; /* 4 */
  top: 35%;
  left: 25%;
  border: 3px solid #accf5b;
}
.status-buttons a {
  color: black;
  display: inline-block;
  font-size: 17px;
  font-weight: normal;
  margin-right: 0;
  text-align: center;
  text-transform: uppercase;
  min-width: 150px;
  text-decoration: none;
}
.status-buttons a:hover {
  text-decoration: none;
}
.status-buttons a.active span {
  color: white;
  background: #accf5b;
  box-shadow: rgba(0, 0, 0, 0.792157) 3px 3px 3px 0;
}
.status-buttons span {
  color: white;
  background: #22bacb;
  display: block;
  height: 45px;
  margin: 0 auto 10px;
  padding-top: 20px;
  width: 60px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.792157) 3px 3px 3px 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none; /* Hide the up-down icons */
}

label[for^='fileInput-'] {
  position: absolute;
  right: 0.5rem; /* Adjust the positioning as needed */
  top: 50%;
  transform: translateY(-50%);
}

/* WelcomeModal.css */
.welcome-modal {
  font-family: 'Pacifico', cursive;
  text-align: center;
  /* background-color: #f5f5f5; */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 800px; /* Set the desired width */
  height: 500px; /* Set the desired height */
}

/* Style the h1 element to make it larger and sexier */
.welcome-modal h1 {
  font-size: 2.2rem; /* Increase the font size to make it larger */
  color: #3e82d1; /* Change the text color */
  font-weight: 900; /* Increase the font weight for a bolder look */
  text-transform: uppercase; /* Uppercase the text for added style */
  letter-spacing: 2px; /* Add some letter spacing for a more spaced-out look */
  margin-bottom: 10px; /* Add some spacing below the heading */
}

/* Rest of the styles remain the same */

/* Styles to center the modal */

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 100;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

/* Modal Content */
/* .modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
} */

/* Styles for the modal overlay */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Styles for the modal content */
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  max-width: 100%;
  max-height: 100%;
  margin-left: 200px;
  margin-top: 20px;
  width: 70%;
  height: 70%;
  text-align: center;
}

.ps-submenu-content {
  margin-left: 1rem !important;
}

.css-ewdv3l {
  border-radius: 0.8rem;
}

/* .ps-menu-button {
  padding: 0 12px !important;
} */

/* submenu hover  */
.ps-menu-button:hover {
  background-image: linear-gradient(90deg, #007eef 0%, #007eef 100%);
  color: black;
  border-radius: 0.5rem;
}

.gradient-text {
  background: -webkit-linear-gradient(#0165BF, #22B6CC);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}

.gradient-text-green {
  background: -webkit-linear-gradient(#00aeef, #6adf23);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.gradient-background {
  background: linear-gradient(
    90deg,
    rgba(0, 174, 239, 0.05) 7.92%,
    rgba(106, 223, 35, 0.05) 89.75%
  );
  /* Other CSS properties can be added as needed */
}

/* Sign-up screens */
.gradient-heading {
  background: linear-gradient(90deg, #00aeef 2.92%, #6adf23 98.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.log-in-style {
  background: linear-gradient(
    90deg,
    #007eef 30.92%,
    #2ae4ff 30.75%,
    #007eef 30.92%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-color-login {
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
}
/* tailwind.css or your custom CSS file */
.hover-underline-blue:hover {
  text-decoration: underline;
  color: blue;
}

.submit-button {
  border-radius: 4px;
  background: var(
    --Website-Gradient-Green,
    linear-gradient(180deg, #42cc71 0%, #28c1a2 100%)
  );

  /* elevation/2 */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.PhoneInputInput {
  outline: none;
  margin-left: 10px;
  background-color: transparent !important;
}
.form-control {
  width: 100% !important;
  outline: none !important;
  padding-top: 25px;
  padding-bottom: 25px;
}
.react-tel-input {
  width: 100%;
}
.react-tel-input .flag-dropdown {
  /* border: none !important; */
  border-right: none !important;
  background: none !important;
}
.react-tel-input .form-control {
  padding-left: 60px !important;
}
.react-tel-input .selected-flag {
  width: 52px !important;
}
.selected-flag .flag {
  margin-left: 10px !important;
}
.react-tel-input .country-list {
  padding-left: 9px !important;
}
/* End Sign-up screen */
select::-ms-expand {
  display: none;
}

.css-1hxo83e {
  background: #0a0528;
}

.ps-sidebar-container::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
  border-radius: 8px;
}

.ps-sidebar-container::-webkit-scrollbar-thumb {
  background-color: #bdbdbd; /* color of the thumb (scrollable area) */
  border-radius: 6px; /* rounded corners for the thumb */
}

.ps-sidebar-container::-webkit-scrollbar-track {
  background-color: white; /* color of the track (non-scrollable area) */
}

.ps-sidebar-container {
  scrollbar-width: thin;
  scrollbar-color: #bdbdbd white;
}

@media screen and (max-width: 1280px) {
  body {
    zoom: 0.8;
    transform-origin: top left;
  }
  /* Hide the default scrollbar */
  body {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
  }

  body::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
  }
  body::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* color of the thumb (scrollable area) */
    border-radius: 6px; /* rounded corners for the thumb */
  }
  body::-webkit-scrollbar-track {
    background-color: white; /* color of the track (non-scrollable area) */
  }

  .login-screen {
    zoom: 1.248;
  }
}
.css-jjygbq {
  overflow-y: visible;
}
.react-joyride__spotlight {
  background-color: wheat;
}
/* .react-joyride__spotlight{
  position: absolute;
  z-index: 20000;
} */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 50%;
}

.modal-close {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.alignment {
  padding-right: 80px !important;
  text-align: right !important;
}

/* Premium styles */

/* PremiumFeature.css */

/* PremiumFeature.css */

.page-container {
  display: flex;
}

.modal-containerr {
  position: relative;
  /* Other styles for the modal container */
}

.modal-backgroundd {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* 20% black overlay */
  backdrop-filter: blur(10px); /* Apply blur effect */
}

.modal-contentt {
  position: relative;
  z-index: 1; /* Place content above the background */
  background-color: white; /* You can adjust the background color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.waste-button-class {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  border-radius: 100px;
  background: var(--action-selected, rgba(0, 0, 0, 0.08));
}

.tabs-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent tabs from wrapping to the next line */
  max-width: 100%; /* Optional: Set a maximum width to avoid excessive scrolling */
  width: 950px;
}

.tabs-container::-webkit-scrollbar {
  display: none;
  /* display: flex; */
  /* background: #1A2F4D; */
}

/* CSS */
.tabs-container.scrollable {
  overflow-x: auto;
  white-space: nowrap; /* Prevent line breaks for inline elements */
}

.modal-center1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.modal-content1 {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 50%;
}

.modal-close1 {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.modal-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}
/* Add blue border to the active tab */
.tab.active {
  border-bottom: 2px solid #2196f3;
}

/* Add blue border to the previous tab */
.tab.previous {
  border-bottom: 2px solid #2196f3;
}
.custom-input::placeholder {
  font-size: 13px;
}
.my-div {
  border-radius: 8px !important;
  background: #fff;
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.15) !important;
  box-sizing: border-box !important;
  /* You can add other styles and content to your div here */
}

.green-gradient-to-r {
  background: -webkit-linear-gradient(#00aeef, #6adf23);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.upload-button {
  background-color: #3B82F6; /* Green */
  border: none;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: #3B82F6;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  height: 3px; /* width of the entire scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  height: 8px; /* color of the tracking area */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scroll thumb */
  border-radius: 0.5rem; /* roundness of the scroll thumb */
  border: 1px solid transparent; /* creates padding around scroll thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scroll thumb when hovering */
}

input[type='checkbox']::before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid #777;
  background-color: white;
  border-radius: 2px;
}
.wordsping{
  word-spacing: 4.2px;
}
.jodit-toolbar-button.jodit-toolbar-button_image,
.jodit-toolbar-button.jodit-toolbar-button_file,
.jodit-toolbar-button.jodit-toolbar-button_video {
  display: none !important;
}
.jodit-ui-group__speechRecognize {
  display: none !important;
}
.jodit-status-bar-link{
  display: none !important;
}
.scrollable-content {
  /* For Webkit-based browsers */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* For Webkit (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    display: none;
  }
}
.table-hader{
  background: linear-gradient(90deg, #007EEF 0%,  #F5FAFF -21.25%);
}
.custom-gradient-text {
  background: linear-gradient(90deg, #00AEEF 7.92%, #6ADF23 89.75%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight:bold; /* Semibold */
}
.custom-gradient-text:hover{
  background: linear-gradient(90deg, #00AEEF 7.92%, #6ADF23 89.75%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight:bold;
}
.custom-select option:first-child {
  color: #d1d5db;
}

.relative-container {
  position: relative;
  width: 100%;  /* Adjust this to set a specific width for the input */
}

.dropdown {
  position: absolute;
  width: 120%; /* Make the dropdown wider than the input */
  top: 100%;   /* Position just below the input */
  left: 0;     /* Align to the left edge of the container */
  z-index: 10; /* Ensure it's above other content */
}

/* select option:checked {
  background-color: rgb(2 132 199 / var(--tw-bg-opacity)) !important;
  color: white;
} */

select option:hover {
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
  color: white;
}

.gradient-background {
  background: linear-gradient(
    90deg,
    rgba(0, 174, 239, 0.05) 7.92%,
    rgba(106, 223, 35, 0.05) 89.75%
  );
  /* Other CSS properties can be added as needed */
}

.org-gradient{
  background: linear-gradient(
    90deg,
    rgba(106, 223, 35, 0.07) 80%,
    rgba(0, 174, 239, 0.07) 20%
  );
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Width of the switch */
  height: 20px; /* Height of the switch */
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Off background color */
  transition: .4s; /* Animation to slide */
  border-radius: 34px; /* Rounded borders */
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px; /* Circle size */
  width: 14px; /* Circle size */
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%; /* Rounded shape for the slider */
}

input:checked + .slider {
  background-color: rgba(66, 204, 113, 1); /* On state color using RGBA */
}

input:focus + .slider {
  box-shadow: 0 0 1px rgba(66, 204, 113, 1); /* Optional: adds a shadow when focused */
}

input:checked + .slider:before {
  transform: translateX(20px); /* Move the circle to the right on toggle */
}

/* Optional: styles for rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.custom-hide-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

